import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import TopTitleBar from "../components/TopTitleBar";

const NotFoundStyles = styled.main`
  grid-column: 2 / span 12;
  display: grid;
  grid-template-columns: repeat(12, minmax(auto, var(--column-width)));
  gap: 0 2rem;
  margin: 8rem 0;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 6;
    grid-template-columns: repeat(6, 1fr);
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    gap: 0 1rem;
    margin: 0;
  }

  h1 {
    margin-bottom: 0.5em;
  }
`;

const Lease = () => {
  return (
    <Layout>
      <Seo title="404" />
      <TopTitleBar title="404" />
      <NotFoundStyles>
        <h1>404</h1>
        <p>This page doesn't exist.</p>
      </NotFoundStyles>
    </Layout>
  );
};

export default Lease;
